<template>
    <layout-main>
        <h2 class="name-page" slot="left">Thông tin cá nhân</h2>
        <div class="block-content-tab-follow">
            <div class="item-content-tab-follow ct-style-block">
                <div class="block-edit-info-page">
                    <div class="content-edit-info-page">
                        <div class="lst-info-user">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-info-new open-form">
                                        <div class="ct-input-info-new">
                                            <label class="label-bold">Họ và tên</label>
                                            <input type="text" class="" v-model="userInfo.fullName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-info-new ">
                                        <div class="ct-input-info-new">
                                            <label class="label-bold">Điện thoại</label>
                                            <input type="number" class="" v-model="userInfo.phoneNumber">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="group-ns-email">

                                        <div class="form-info-new form-mail-fix">
                                            <div class="ct-input-info-new">
                                                <label class="label-bold">Email</label>
                                                <input type="text" class="" v-model="userInfo.email">
                                                <span class="icon-lock">
                                                    <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.75 7.33083V4.91898C12.748 3.61498 12.2172 2.36495 11.2738 1.44288C10.3305 0.52081 9.05159 0.00193904 7.7175 0H7.2825C5.94841 0.00193904 4.66952 0.52081 3.72618 1.44288C2.78283 2.36495 2.25198 3.61498 2.25 4.91898V7.33083C1.65326 7.33083 1.08097 7.56253 0.65901 7.97497C0.237053 8.38741 0 8.9468 0 9.53007V16.3477C0.00198284 17.4554 0.45302 18.5171 1.25431 19.3003C2.0556 20.0835 3.14181 20.5244 4.275 20.5263H10.725C11.8582 20.5244 12.9444 20.0835 13.7457 19.3003C14.547 18.5171 14.998 17.4554 15 16.3477V9.53007C15 8.9468 14.7629 8.38741 14.341 7.97497C13.919 7.56253 13.3467 7.33083 12.75 7.33083ZM3.75 4.91898C3.75 4.00324 4.12217 3.125 4.78465 2.47747C5.44712 1.82994 6.34562 1.46617 7.2825 1.46617H7.7175C8.65438 1.46617 9.55288 1.82994 10.2154 2.47747C10.8778 3.125 11.25 4.00324 11.25 4.91898V7.33083H3.75V4.91898ZM13.5 16.3477C13.5 17.0671 13.2076 17.757 12.6872 18.2657C12.1668 18.7744 11.461 19.0601 10.725 19.0601H4.275C3.53902 19.0601 2.83319 18.7744 2.31278 18.2657C1.79237 17.757 1.5 17.0671 1.5 16.3477V9.53007C1.5 9.33565 1.57902 9.14919 1.71967 9.01171C1.86032 8.87423 2.05109 8.79699 2.25 8.79699H12.75C12.9489 8.79699 13.1397 8.87423 13.2803 9.01171C13.421 9.14919 13.5 9.33565 13.5 9.53007V16.3477Z" fill="#777E8A" />
                                                        <path d="M8.25 13.7233V16.1278C8.25 16.3222 8.17098 16.5087 8.03033 16.6462C7.88968 16.7837 7.69891 16.8609 7.5 16.8609C7.30109 16.8609 7.11032 16.7837 6.96967 16.6462C6.82902 16.5087 6.75 16.3222 6.75 16.1278V13.7233C6.46404 13.5619 6.24054 13.3128 6.11418 13.0146C5.98782 12.7165 5.96565 12.3859 6.05111 12.0741C6.13657 11.7623 6.32489 11.4869 6.58686 11.2904C6.84882 11.0939 7.1698 10.9874 7.5 10.9874C7.8302 10.9874 8.15118 11.0939 8.41314 11.2904C8.67511 11.4869 8.86343 11.7623 8.94889 12.0741C9.03435 12.3859 9.01218 12.7165 8.88582 13.0146C8.75946 13.3128 8.53596 13.5619 8.25 13.7233Z" fill="#777E8A" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <select-date-drop v-model="userInfo.birthdate"></select-date-drop>
                                        <!--<div class="lst-form-date-ns">
                                            <div class="form-info-new ">
                                                <div class="ct-input-info-new">
                                                    <label class="label-bold">Ngày sinh</label>
                                                    <select name="" id="">
                                                        <option value="" selected>12</option>
                                                        <option value="">23</option>
                                                        <option value="">14</option>
                                                        <option value="">15</option>
                                                    </select>
                                                    <span class="icon-select-bt">
                                                        <img src="img-fix/icon-select-bottom.svg" alt="">
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="form-info-new ">
                                                <div class="ct-input-info-new">
                                                    <label class="label-bold">Tháng</label>
                                                    <select name="" id="">
                                                        <option value="">1</option>
                                                        <option value="" selected>2</option>
                                                        <option value="">3</option>
                                                        <option value="">4</option>
                                                    </select>
                                                    <span class="icon-select-bt">
                                                        <img src="img-fix/icon-select-bottom.svg" alt="">
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="form-info-new ">
                                                <div class="ct-input-info-new">
                                                    <label class="label-bold">Năm</label>
                                                    <select name="" id="">
                                                        <option value="">1995</option>
                                                        <option value="" selected>1996</option>
                                                        <option value="">1997</option>
                                                        <option value="">1998</option>
                                                    </select>
                                                    <span class="icon-select-bt">
                                                        <img src="img-fix/icon-select-bottom.svg" alt="">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>-->
                                    </div>

                                </div>

                                <div class="col-12">
                                    <div class="form-info-new ">
                                        <div class="ct-input-info-new">
                                            <label class="label-bold">Địa chỉ</label>
                                            <input type="text" class="" v-model="userInfo.address">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-popup-action">
                        <div class="left-bottom-popup-action">
                            <div class="btn-cancel">
                                <a href="javascript:;" @click="resetData" class="btn-text">Đặt lại</a>
                            </div>
                        </div>
                        <div class="right-bottom-popup-action">
                            <div class="btn-save">
                                <a href="javascript:;" @click="updateGeneralInfo" class="btn-bg-linear">Lưu thay đổi</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </layout-main>
        <!--<div class="container">
        <div class="main-ct">-->
        <!-- {{client_configs}} -->
        <!-- [D] Thêm class "edit" vào class "box" khi click vào nút Sửa -->
        <!--<div class="box box-general-info edit">
          <div class="box-head" v-if="!iframe">
              <div class="back-steps-id" v-if="isEdit">
                  <a href="javascript:void(0):" @click="isEdit = false">
                      <svg xmlns="http://www.w3.org/2000/svg"
                           width="17"
                           height="13"
                           viewBox="0 0 17 13"
                           fill="none">
                          <path d="M5.75452 1.07142C5.98093 0.845009 6.33737 0.845009 6.56378 1.07142C6.78254 1.29018 6.78254 1.65427 6.56378 1.87252L2.46037 5.97592H16.0011C16.3167 5.97592 16.5757 6.22681 16.5757 6.54246C16.5757 6.8581 16.3167 7.11715 16.0011 7.11715H2.46037L6.56378 11.2129C6.78254 11.4393 6.78254 11.8039 6.56378 12.0222C6.33737 12.2486 5.98093 12.2486 5.75452 12.0222L0.679695 6.94734C0.460935 6.72858 0.460935 6.36449 0.679695 6.14624L5.75452 1.07142Z"
                                fill="#414B5B"></path>
                      </svg>
                  </a>
              </div>
              <h2>{{ $t("info_title") }}</h2>
              <button type="button"
                      class="btn-edit"
                      @click="editProfile"
                      v-if="!isEdit">
                  {{ $t("info_edit") }}
              </button>
          </div>
          <div class="box-ct">
              <ul class="general-info-idBnd" v-show="!isEdit">
                  <div class="item-general">
                      <div class="title">{{ $t("info_firstname") }}:</div>
                      <div class="ct">{{ userInfo.firstName }}</div>
                  </div>
                  <div class="item-general">
                      <div class="title">{{ $t("info_lastname") }}:</div>
                      <div class="ct">{{ userInfo.lastName }}</div>
                  </div>
                  <div class="item-general">
                      <div class="title">{{ $t("info_gender") }}:</div>
                      <div class="ct">{{ userInfo.gender_text }}</div>
                  </div>

                  <div class="item-general">
                      <div class="title">{{ $t("info_birthdate") }}:</div>
                      <div class="ct">
                          {{ userInfo.birthdate | moment("DD/MM/YYYY") }}
                      </div>
                  </div>

                  <div class="item-general">
                      <div class="title">{{ $t("info_language") }}:</div>
                      <div class="ct">{{ userInfo.language_text }}</div>
                  </div>
              </ul>
              <ul class="row lst-itm-info pb-0" v-show="isEdit">
                  <li class="col-sm-6">
                      <div class="custom-input"
                           :class="{
          'is-err': submitted && !$v.userInfo.firstName.required,
        }">-->
        <!-- [D] Khi chuyển sang màn hình sửa, xoá thuộc tính readonly -->
        <!--<input type="text"
                                 id="ipt-name"
                                 class="ipt"
                                 v-model="userInfo.firstName"
                                 :readonly="!isEdit" />
                          <label for="ipt-name" class="lbl">
                              {{ $t("info_firstname") }}
                              <span class="obl">*</span>
                          </label>
                      </div>
                      <div class="txt-mess ml-0 text-left"
                           v-if="submitted && !$v.userInfo.firstName.required">
                          {{ $t("info_lastname_required") }}
                      </div>
                  </li>
                  <li class="col-sm-6">
                      <div class="custom-input"
                           :class="{
          'is-err': submitted && !$v.userInfo.lastName.required,
        }">-->
        <!-- [D] Khi chuyển sang màn hình sfửa, xoá thuộc tính readonly -->
        <!--<input type="text"
                       id="ipt-lastname"
                       class="ipt"
                       v-model="userInfo.lastName"
                       :readonly="!isEdit" />
                <label for="ipt-last" class="lbl">{{ $t("info_lastname") }} <span class="obl">*</span></label>
            </div>
            <div class="txt-mess ml-0 text-left"
                 v-if="submitted && !$v.userInfo.lastName.required">
                {{ $t("info_firstname_required") }}
            </div>
        </li>
        <li class="col-sm-6 col-xl-6">
            <um-web-select-ul :options="genderOptions"
                               code="value"
                               label="name"
                               :displayText="$t('info_gender')"
                               v-model="userInfo.gender"
                               :showSearch="false"
                               :disabled="!isEdit"
                               :isRequired="true"
                               :isSubmitted="submitted" />
            <div class="txt-mess ml-0 text-left"
                 v-if="submitted && !$v.userInfo.gender.required">
                {{ $t("info_gender_required") }}
            </div>
        </li>

        <li class="col-sm-6 col-xl-6">-->
        <!-- [D] Thêm class "open" vào class "selbox"  để mở selectbox -->
        <!--<um-web-select-ul :options="languageOptions"
                               code="code"
                               label="name"
                               :displayText="$t('info_language')"
                               v-model="userInfo.languageId"
                               :showSearch="languageOptions && languageOptions.length > 10"
                               :disabled="!isEdit"
                               :isRequired="true"
                               :isSubmitted="submitted" />
            <div class="txt-mess ml-0 text-left"
                 v-if="submitted && !$v.userInfo.languageId.required">
                {{ $t("info_language_required") }}
            </div>
        </li>-->
        <!-- {{new Date(userInfo.birthdate)}} -->
        <!--<um-web-date-drop v-model="userInfo.birthdate"
                                       min="1900"
                                       :max="new Date().getFullYear() - 3 + ''"
                                       :isRequired="true"
                                       :disabled="!isEdit" />
                </ul>
                <div class="btn-area" v-if="isEdit">
                    <button type="button"
                            class="btn btn-update"
                            @click="updateGeneralInfo">
                        {{ $t("info_button_update") }}
                    </button>
                    <button type="button" class="btn button-cancel-info" @click="isEdit = false" v-if="!iframe">
                        {{ $t("cancel") }}
                    </button>
                </div>
            </div>
        </div>
        <div class="box box-acc-link" v-if="!iframe">
            <div class="box-head">
                <h2>{{ $t("info_social_media_link") }}</h2>
            </div>
            <ul class="box-ct lst-ct">-->
        <!-- <li>
          <div class="info-link d-flex align-items-center">
            <img src="id/logo-zalo.svg" alt="" />
            <span class="txt-tit">Zalo</span>
          </div>
          <a
            href="javascript:void(0);"
            class="itm-link"
            @click="showLinkTo('zalo_link')"
            >{{ $t("info_link") }}</a
          >
        </li> -->
        <!--<li>-->
        <!-- {{ google_link }} -->
        <!--<div class="info-link d-flex align-items-center">
                                <img src="id/logo-fb.svg" alt="" />
                                <span class="txt-tit">Facebook</span>
                            </div>
                            <facebook-login :app-id="client_configs.facebookAppId"
                                            @login="loginViaFacebook"
                                            v-show="!facebook_link">
                                <a slot="body" class="itm-link">{{ $t("info_link") }}</a>
                            </facebook-login>
                            <a href="javascript:;"
                               @click="logoutFacebook"
                               class="itm-link"
                               v-show="facebook_link">{{ $t("info_logout_link") }}</a>
                        </li>
                        <li>
                            <div class="info-link d-flex align-items-center">
                                <img src="id/logo-google.svg" alt="" />
                                <span class="txt-tit">Google</span>
                            </div>

                            <google-login :client-id="client_configs.googleClientId"
                                          :api-key="client_configs.googleAPIKey"
                                          @login="loginViaGoogle"
                                          v-show="!google_link">
                                <a slot="body" class="itm-link">{{ $t("info_link") }}</a>
                            </google-login>
                            <a href="javascript:;"
                               @click="logoutGoogle"
                               class="itm-link"
                               v-show="google_link">{{ $t("info_logout_link") }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <modalcustom v-if="showLink">
            <div class="modal fade popup show" slot="content">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">-->
        <!-- HTML của phần thêm email mới -->
        <!--<div class="modal-header justify-content-center">
                            <h5 class="modal-title">{{ $t("info_link_to_title") }}</h5>
                        </div>
                        <div class="modal-body type2">
                            <div class="custom-input mt-3 mb-2">
                                <input type="ClaimValue"
                                       v-model="currentClaim.ClaimValue"
                                       id="ClaimValue"
                                       name="ClaimValue"
                                       class="form-control" />
                                <label for="addEmail" class="lbl">
                                    {{
                  $t("info_link_to_lbl")
                                    }}
                                </label>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-around">
                            <button type="button"
                                    class="btn btn-light"
                                    data-dismiss="modal"
                                    @click="hideLinkTo">
                                {{ $t("email_cancel") }}
                            </button>
                            <button type="button"
                                    class="btn btn-complete"
                                    @click="updateUserClaim">
                                {{ $t("email_save") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modalcustom>-->
</template>
<script>
    import { required } from "vuelidate/lib/validators";
    export default {
        props: ['iframe'],
        components: {
            /*modalcustom*/
        },
        data() {
            return {
                isLoaded: false,
                isEdit: false,
                userInfo: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    birthdate: null,
                    gender: null,
                    languageId: null,
                    userClaims: null,
                    gender_text: null,
                    language_text: null,
                    birthdate_text: null,
                },
                showLink: false,
                currentClaim: {},
                genderOptions: [],
                languageOptions: [],
                dayOptions: [],
                submitted: false,
                client_configs: {},
            };
        },
        validations: {
            userInfo: {
                fullName: { required }
            },
        },
        created() {
            //this.client_configs = this.appSettings.configs;
            this.getUserInfo();
            if (this.iframe)
                this.editProfile()
        },
        mounted() {
        },
        methods: {
            getUserInfo() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.USER_GET_PROFILE)
                    .then((data) => {
                        this.userInfo = data.result;
                        //var default_language = data.result.userClaims.find(
                        //    (x) => x.claimType == this.appSettings.userClaimType.DefaultLanguage
                        //);
                        //if (default_language) {
                        //    this.userInfo.languageId = default_language.claimValue;
                        //}
                        //this.mappingText();
                        $loading.hide();
                        // Convert Ngôn ngữ
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            mappingText() {
                if (this.userInfo.languageId) {
                    this.userInfo.language_text = this.languageOptions.find(
                        (x) => x.code === this.userInfo.languageId
                    ).name;
                }
                if (this.userInfo.birthdate) {
                    this.userInfo.birthdate_text = new Date(this.userInfo.birthdate)
                        .toJSON()
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("/");
                }
                if (this.userInfo.gender) {
                    var gender_value = this.genderOptions.find(
                        (x) => x.value === this.userInfo.gender
                    ).name;
                    this.userInfo.gender_text = this.$t(gender_value);
                }
            },
            updateGeneralInfo() {
                this.submitted = true;
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const $loading = this.$loading.show();
                    this.$axios
                        .put(this.$api.USER_UPDATE_INFO, this.userInfo)
                        .then((data) => {
                            // this.getEmail();
                            this.$success(this.$t("info_noti_info_success"));
                            this.mappingText();
                            $loading.hide();

                            if (typeof (window) != undefined && this.iframe)
                                window.flutter_inappwebview.callHandler('updateSuccess', 'info');
                            else
                                this.isEdit = false;
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                }
            },
            updateUserClaim(userClaim, callback) {
                const $loading = this.$loading.show();
                this.$axios
                    .put(this.$api.USER_UPDATE_CLAIM, userClaim)
                    .then((data) => {
                        // this.getEmail();
                        this.$success(this.$t("info_noti_info_success"));
                        $loading.hide();
                        callback();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            editProfile() {
                this.isEdit = true;
            },
            showLinkTo(type) {
                let claim = this.userInfo.userClaims.find((x) => x.claimType == type);
                if (claim) {
                    this.currentClaim = claim;
                } else {
                    this.currentClaim = {
                        ClaimType: type,
                        ClaimValue: null,
                    };
                }
                this.showLink = true;
            },
            hideLinkTo() {
                this.showLink = false;
            },
            loginViaFacebook(info) {
                var vm = this;
                if (info && info.token && info.email && info.id) {
                    var currentClaim = {
                        ClaimType: this.appSettings.userClaimType.FacebookLink,
                        ClaimValue: info.email,
                    };
                    this.updateUserClaim(currentClaim, () => {
                        vm.facebook_link = info.email;
                    });
                } else {
                    this.$error(this.$t("login_invalid_external_info"));
                }
            },
            loginViaGoogle(info) {
                var vm = this;
                if (info && info.token && info.email && info.id) {
                    var currentClaim = {
                        ClaimType: this.appSettings.userClaimType.GoogleLink,
                        ClaimValue: info.email,
                    };
                    this.updateUserClaim(currentClaim, () => {
                        vm.google_link = info.email;
                    });
                } else {
                    this.$error(this.$t("login_invalid_external_info"));
                }
            },
            logoutGoogle() {
                var vm = this;
                this.$confirm(
                    this.$t("address_confirm_delete"),
                    this.$t("address_confirm_confirm"),
                    this.$t("address_confirm_cancel")
                ).then(() => {
                    var auth2 = gapi.auth2.getAuthInstance();
                    auth2.signOut().then(function () {
                        var currentClaim = {
                            ClaimType: vm.appSettings.userClaimType.GoogleLink,
                            ClaimValue: "",
                        };
                        vm.updateUserClaim(currentClaim, () => {
                            vm.google_link = "";
                        });
                    });
                    auth2.disconnect();
                });
            },
            logoutFacebook() {
                var vm = this;
                this.$confirm(
                    this.$t("address_confirm_delete"),
                    this.$t("address_confirm_confirm"),
                    this.$t("address_confirm_cancel")
                ).then(() => {
                    FB.getLoginStatus(function (response) {
                        if (response && response.status === "connected") {
                            FB.logout(function (response) {
                                var currentClaim = {
                                    ClaimType: vm.appSettings.userClaimType.FacebookLink,
                                    ClaimValue: "",
                                };
                                vm.updateUserClaim(currentClaim, () => {
                                    vm.facebook_link = "";
                                });
                            });
                        }
                    });
                });
            },
            resetData() {

            }
        },
    };
</script>

<style scoped>
    .popup .modal-dialog {
        width: 400px;
        height: 304px;
    }
</style>
