<template>
    <div style="text-align:center; margin:200px auto;">
        <div v-if="error" style="color:red">{{error}}</div>
        <a href="javascript:void(0)" @click="$router.go(-1)">Back</a>
    </div>
</template>
<script type="text/javascript">

    import { ACCOUNT_GET_ERROR } from '../../constants/api'
    export default {
        data() {
            return { error: '' };
        },
        async created() {
            const query = window.location.search;
            const errorIdQuery = query && query.toLowerCase().indexOf('?errorid=') == 0 && query;

            const response = await fetch(`${this.$api.ACCOUNT_GET_ERROR}${errorIdQuery}`, {
                credentials: 'include',
            });
            const data = await response.json();
            this.error = data.result.errorDescription;            
        },
    };
</script>
