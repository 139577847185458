<template>
    <div style="margin:100px auto; text-align:center;">
        <div class="lds-dual-ring">Đang thoát...</div>
    </div>
</template>
<script>
    export default {
        created() {
            this.$auth.signOut()
        }
    };
</script>
